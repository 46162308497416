import { Dispatch, SetStateAction, useState } from "react"
import { retailer } from "../../../../../types/retailer"

interface activeRetailerReturns {
  activeRetailer: retailer | null
  setActiveRetailer: Dispatch<SetStateAction<retailer | null>>
}

export const useActiveRetailer = (): activeRetailerReturns => {
  // Hold active retailer in local state
  const [activeRetailer, setActiveRetailer] = useState<retailer | null>(null)

  return {
    activeRetailer,
    setActiveRetailer,
  }
}
