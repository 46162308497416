import Axios from "axios"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { retailer } from "../../../../../types/retailer"
import { coordinates } from "./useMap"

// user location prop types
interface userLocationProps {
  focusOnLocation: (coords: coordinates) => void
  setActiveRetailer: Dispatch<SetStateAction<retailer | null>>
}

// user location hook return types
interface userLocationReturns {
  postcode: string
  setPostcode: React.Dispatch<React.SetStateAction<string>>
  handleSubmit: () => void
  userCoords: coordinates | null
}

export const useUserLocation = ({
  focusOnLocation,
  setActiveRetailer,
}: userLocationProps): userLocationReturns => {
  // Hold the value of the input in local state
  const [postcode, setPostcode] = useState<string>("")

  // Hold user location in current state
  const [userCoords, setUserCoords] = useState<coordinates | null>(null)

  const handleSubmit = () => {
    submitForm(postcode)
  }

  const submitForm = async (postcode: string) => {
    // Fetch postcode data from postcode api
    const request = await Axios.get(
      `https://api.postcodes.io/postcodes/${postcode}`
    )
    // Extract coords if postcode is found
    const { latitude, longitude } = request?.data?.result || {}
    // Check if postcode was found
    if (!latitude || !longitude) return { error: "Postcode not found" }
    // Hold and convert to coordinates object
    const coords = { lat: latitude, lng: longitude }
    // Set the users location
    setUserCoords(coords)
    // focus on postcode coordinates
    focusOnLocation(coords)
    return true
  }

  //   Reset active retailer on user coords change
  useEffect(() => {
    setActiveRetailer(null)
  }, [userCoords])

  return {
    postcode,
    setPostcode,
    handleSubmit,
    userCoords,
  }
}
