import React, { FC, useContext } from "react"
import { retailer } from "../../../../../../types/retailer"
import { RetailersContext } from "../../retailer-page-hooks/retailersContext"
import style from "./marker.module.scss"
// Set up marker prop types
interface markerProps {
  retailer: retailer
  lat: number
  lng: number
}

const Marker: FC<markerProps> = ({ retailer }) => {
  // Extract active retailer from context
  const { activeRetailer, setActiveRetailer } = useContext(RetailersContext)
  // Guard clause for context
  if (typeof activeRetailer === "undefined" || !setActiveRetailer) return null
  // Get the current active retailers slug
  const { slug: activeSlug } = activeRetailer || {}
  // Extract the nessesary fields from the current retailer object
  const { slug } = retailer
  // Determine whether the current retailer is active
  const active = slug === activeSlug
  return (
    <div className={style.wrapper}>
      <button
        aria-label="Retailer map marker. Click to set current retailer as active and see further details on retailer."
        className={style.button}
        onClick={e => {
          e.stopPropagation()
          setActiveRetailer(retailer)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
        >
          <path data-name="Path 109" d="M0 0h24v24H0z" fill="none" />
          <path
            data-name="Path 110"
            d="M12 2a7 7 0 00-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 00-7-7zm0 9.5A2.5 2.5 0 1114.5 9a2.5 2.5 0 01-2.5 2.5z"
            // Change marker colour depending on active state
            fill={active ? "#FF52A8" : "#00a0f0"}
          />
        </svg>
      </button>
    </div>
  )
}

export default Marker
