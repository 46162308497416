import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { retailer } from "../../../../../types/retailer"
import { coordinates } from "./useMap"
import { orderByDistance } from "geolib"

interface nearestRetailersProps {
  activeRetailer: retailer | null
  retailers: retailer[] | null
  userCoords: coordinates | null
}

interface nearestRetailersReturns {
  nearestRetailers: retailer[] | null
  showCards: boolean
  setShowCards: Dispatch<SetStateAction<boolean>>
}

export const useNearestRetailers = ({
  activeRetailer,
  retailers,
  userCoords,
}: nearestRetailersProps): nearestRetailersReturns => {
  // Hold list of the nearest retailers in local state
  const [nearestRetailers, setNearestRetailers] = useState<retailer[] | null>(
    null
  )

  // Control whether cards show in local state
  const [showCards, setShowCards] = useState<boolean>(false)

  //   If retailers arent set yet abort
  if (!retailers) return { nearestRetailers, showCards, setShowCards }

  //   Function to get all of the closest retailers
  const getClosestRetailers = async (coords: coordinates) => {
    //   Reorder the retailers by distance
    const orderedRetailers = orderByDistance(
      { latitude: coords.lat, longitude: coords.lng },
      retailers
        //   Extract the coords to a top level to enable function
        .map(({ address, ...rest }) => ({
          latitude: address.latitude,
          longitude: address.longitude,
          address: address,
          ...rest,
        }))
        // Filter retailers with no coords
        .filter(({ latitude, longitude }) => latitude && longitude)
    )

    // If ordereder retailers fails abort
    if (!orderedRetailers || orderedRetailers.length === 0) return

    // Extract the five closest retailers
    const fiveClosest = orderedRetailers
      .slice(0, 5)
      //   @ts-ignore
      .map(({ latitude, longitude, ...rest }) => ({ ...rest }))

    // Set the nearest retailers in the state
    //   @ts-ignore
    setNearestRetailers(fiveClosest)
  }

  /* useEffect(() => {
    console.log("test", test)
    if (!activeRetailer) {
      setNearestRetailers(null)
      return
    }

    const { address } = activeRetailer
    const { latitude: lat, longitude: lng } = address
    // Run async function to get the closest retailers
    getClosestRetailers({ lat, lng })
  }, [activeRetailer]) */

  useEffect(() => {
    //   If user coords is false set nearest retailers to null
    if (!userCoords) {
      setNearestRetailers(null)
      return
    }
    // Run async function to get the closest retailers
    getClosestRetailers(userCoords)
  }, [userCoords])

  useEffect(() => {
    console.log("nearestRetailers", nearestRetailers)
    setShowCards(nearestRetailers ? true : false)
  }, [nearestRetailers, setShowCards])

  return { nearestRetailers, showCards, setShowCards }
}
