import { Dispatch, SetStateAction, useEffect } from "react"
import { useGetAndSetRetailers } from "./getAndSetRetailers"
import { useActiveRetailer } from "./activeRetailer"
import { retailer } from "../../../../../types/retailer"
import { coordinates, useMap } from "./useMap"
import { ChangeEventValue } from "google-map-react"
import { useUserLocation } from "./userLocation"
import { useNearestRetailers } from "./nearestRetailers"
// Set up retailers logic inside this hook
export interface retailersHookReturns {
  postcode: string
  setPostcode: React.Dispatch<React.SetStateAction<string>>
  handleSubmit: () => void
  userCoords: coordinates | null
  retailers: retailer[]
  activeRetailer: null | retailer
  setActiveRetailer: Dispatch<SetStateAction<retailer | null>>
  coordinates: coordinates
  zoom: number
  handleMapChange: (event: ChangeEventValue) => void
  focusOnLocation: (coords: coordinates) => void
  nearestRetailers: retailer[] | null
  showCards: boolean
  setShowCards: Dispatch<SetStateAction<boolean>>
}

export const useRetailers = (): retailersHookReturns => {
  // Bring in logic to get and set retailers
  const retailers = useGetAndSetRetailers()
  // Bring in logic active retailer logic from hook
  const { activeRetailer, setActiveRetailer } = useActiveRetailer()
  // Bring in map functionality and controls with map hook
  const map = useMap()
  // Extract nessasary functionality from usemap hook
  const { focusOnLocation } = map
  // Bring in functionality for users location
  const userLocation = useUserLocation({ focusOnLocation, setActiveRetailer })
  const { userCoords } = userLocation
  // Bring in nearest retailers functionality
  const nearestRetailersHook = useNearestRetailers({
    activeRetailer,
    retailers,
    userCoords,
  })

  const { setShowCards } = nearestRetailersHook
  useEffect(() => {
    if (activeRetailer) {
      // Get the address
      const { address } = activeRetailer
      // Get the coordinates
      const { latitude, longitude } = address
      // Run function to focus on location
      focusOnLocation({ lat: latitude, lng: longitude })
      setShowCards(false)
    }
  }, [activeRetailer])

  return {
    retailers,
    activeRetailer,
    setActiveRetailer,
    ...map,
    ...userLocation,
    ...nearestRetailersHook,
  }
}
