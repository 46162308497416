import React, { useContext } from "react"
import { RetailersContext } from "../../retailer-page-hooks/retailersContext"
import { coordinates } from "../../retailer-page-hooks/useMap"
import style from "./userMarker.module.scss"

// @ts-ignore
const UserMarker = (coords: coordinates) => {
  // Extract nessesary functionality from context
  const { focusOnLocation, setActiveRetailer, setShowCards } = useContext(
    RetailersContext
  )

  //   Guard clause for context
  if (
    focusOnLocation === undefined ||
    setActiveRetailer === undefined ||
    setShowCards === undefined
  )
    return null

  // Focus on user location and reset active retailer
  const handleClick = (e: any) => {
    e.stopPropagation()
    focusOnLocation(coords)
    setActiveRetailer(null)
    setShowCards(true)
  }

  return (
    <div className={style.wrapper}>
      <button className={style.button} onClick={handleClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm0 2a2 2 0 11.001 4.001A2 2 0 0112 4zm0 10c-1.67 0-3.14-.85-4-2.15.02-1.32 2.67-2.05 4-2.05s3.98.73 4 2.05A4.783 4.783 0 0112 14z" />
        </svg>
      </button>
    </div>
  )
}

export default UserMarker
