import React, { createContext, FC, ReactNode } from "react"
import { retailersHookReturns, useRetailers } from "./useRetailers"

// Initialize retailers context
export const RetailersContext = createContext<Partial<retailersHookReturns>>({})

// Props for the retailers context wrapper
interface retailersContextWrapperProps {
  children: ReactNode
}

// Create context wrapper to add to page
export const RetailersContextWrapper: FC<retailersContextWrapperProps> = ({
  children,
}) => {
  // Run retailers hook to extract logic
  const retailers = useRetailers()
  return (
    <RetailersContext.Provider value={retailers}>
      {children}
    </RetailersContext.Provider>
  )
}
