import { useEffect, useState } from "react"
import { retailer } from "../../../../../types/retailer"
import { storyblokStory } from "../../../../../types/storyblok"
import { storyblok } from "../../../utilities/storyblok/storyblok"

export const useGetAndSetRetailers = () => {
  // Hold full list of retailers in local state
  const [retailers, setRetailers] = useState<retailer[]>([])

  // Hold wether to fetch more retailers
  const [moreToGet, setMoreToGet] = useState<any>(true)

  //   Hold pagination page in local state
  const [page, setPage] = useState<number>(1)

  //   Async function to fetch retailers
  const getRetailers = async () => {
    const request = await storyblok.get("cdn/stories", {
      version: "draft",
      page: page,
      filter_query: {
        component: {
          in: "retailer",
        },
      },
    })
    // Extract nessesary data from request
    const { data, perPage, total } = request
    // Extract stories from data
    const { stories } = data
    // guard clause for no stories
    if (!stories || stories.length === 0) return

    // Get nessesary data for functionality
    const storyData = stories.map((story: storyblokStory) => ({
      name: story.name,
      slug: story.slug,
      ...story.content,
    }))

    // Get total pagination pages
    const pageCount = Math.ceil(total / perPage)

    // Handle pagination
    if (page === pageCount) {
      setMoreToGet(false)
    } else {
      setPage(page + 1)
    }
    // Set the retailers in local state
    setRetailers([...retailers, ...storyData])
  }

  useEffect(() => {
    // Guard clause for no more retailers to fetch
    if (!moreToGet) return
    getRetailers()
  }, [retailers, moreToGet])

  return retailers
}
