import { useContext, useEffect, useState } from "react"
import { retailer } from "../../../../../../types/retailer"
import { RetailersContext } from "../../retailer-page-hooks/retailersContext"
import { convertDistance, getDistance as geolibGetDistance } from "geolib"

export const useGetDistance = (retailer: retailer) => {
  // Get user coords from context
  const { userCoords } = useContext(RetailersContext)

  //   Guard clause for retailer and context
  if (!userCoords || !retailer) return null

  //   Extract coords from retailer
  const {
    address: { latitude, longitude },
  } = retailer

  //   Hold the distance in local state
  const [distance, setDistance] = useState<null | string>(null)

  //   Async function to calculate distance in miles
  const getDistance = async () => {
    const dist = geolibGetDistance(
      { lat: latitude, lon: longitude },
      userCoords
    )
    const convertedDist = convertDistance(dist, "mi")
    setDistance(convertedDist.toFixed(1))
  }

  //   run get distance every time user coordinates change
  useEffect(() => {
    getDistance()
  }, [userCoords])

  return distance
}
