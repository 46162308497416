import { ChangeEventValue } from "google-map-react"
import { useState } from "react"
import { useGetViewportWidth } from "../../../hooks/viewport"

export interface coordinates {
  lat: number
  lng: number
}

// Set up return types for map hook
interface mapHookReturns {
  coordinates: coordinates
  zoom: number
  handleMapChange: (event: ChangeEventValue) => void
  focusOnLocation: (coords: coordinates) => void
}

export const useMap = (): mapHookReturns => {
  // Hold coordinates in local state - initial is manchester
  const [coordinates, setCoordinates] = useState({
    lat: 53.473346,
    lng: -2.209043,
  })

  //   Hold zoom value in local state - initial is 6
  const [zoom, setZoom] = useState<number>(6)
  const handleMapChange = (event: ChangeEventValue) => {
    // extract new center and zoom from event
    const { center, zoom } = event
    // Set local coordinates and zoom
    setCoordinates(center)
    setZoom(zoom)
  }

  const { isDesktop } = useGetViewportWidth()

  const focusOnLocation = (coords: coordinates) => {
    // Extract lat and lng
    const { lat, lng } = coords

    // Set local coordinates and zoom lower the lng to centralise with consideration of retailer card
    isDesktop
      ? setCoordinates({ lat: lat - 0.002, lng: lng - 0.04 })
      : setCoordinates({ lat: lat - 0.01, lng })

    setZoom(13)
  }

  return {
    coordinates,
    zoom,
    handleMapChange,
    focusOnLocation,
  }
}
