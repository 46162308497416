import React, { useContext } from "react"
import style from "./retailerMap.module.scss"
import GoogleMapReact from "google-map-react"
import Marker from "./marker/marker"
import { RetailersContext } from "../retailer-page-hooks/retailersContext"
import UserMarker from "./user-marker/userMarker"
import mapStyle from "./mapStyle.json"

const defaultProps = {
  center: {
    lat: 53.473346,
    lng: -2.209043,
  },
  zoom: 6,
}

const RetailersMap = () => {
  // Pull required data from context
  const {
    retailers,
    userCoords,
    coordinates,
    zoom,
    handleMapChange,
    setActiveRetailer,
    setShowCards,
  } = useContext(RetailersContext)

  // Guard clause for context
  if (
    [
      retailers,
      userCoords,
      coordinates,
      zoom,
      handleMapChange,
      setActiveRetailer,
      setShowCards,
    ].includes(undefined)
  )
    return null

  const handleMapClick = () => {
    // @ts-ignore
    setActiveRetailer(null)
    // @ts-ignore
    setShowCards(false)
  }

  return (
    <div className={style.mapWrapper}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_KEY || "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={coordinates}
        zoom={zoom}
        onClick={handleMapClick}
        onChange={handleMapChange}
        options={{
          zoomControl: true,
          fullscreenControl: false,
          styles: mapStyle,
        }}

        // style={mapStyle}
      >
        {retailers?.map(retailer => {
          const { address, slug } = retailer
          return address.latitude ? (
            address.longitude ? (
              <Marker
                key={`retailerMarker_${slug}`}
                lat={address.latitude}
                lng={address.longitude}
                retailer={retailer}
              />
            ) : null
          ) : null
        })}
        {userCoords && <UserMarker lat={userCoords.lat} lng={userCoords.lng} />}
      </GoogleMapReact>
    </div>
  )
}

export default RetailersMap
