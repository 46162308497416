import { motion } from "framer-motion"
import React, { FC, useContext, useRef } from "react"
import { retailer } from "../../../../../../types/retailer"
import LinkButton from "../../../../utilities/buttons/link-button/linkButton"
import { RetailersContext } from "../../retailer-page-hooks/retailersContext"
import style from "./retailerCard.module.scss"
import directionsIcon from "../../../../../images/directions-rounded-black-18dp.svg"
import callIcon from "../../../../../images/call-black-18dp.svg"
import webIcon from "../../../../../images/launch-black-18dp.svg"
import { useGetDistance } from "./distanceToUser"
import { useGetViewportWidth } from "../../../../hooks/viewport"

interface retailerCardProps {
  retailer: retailer
  key: string
  index: number
  className?: string
}

// Function to set animations on wrapper
const wrapperAnimations = (index: number) => ({
  initial: {
    opacity: 0,
    y: 400,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: index / 10,
    },
  },
  exit: {
    opacity: 0,
    y: 400,
    transition: {
      delay: index / 10,
    },
  },
})

const RetailerCard: FC<retailerCardProps> = ({
  retailer,
  index,
  className,
}) => {
  const distance = useGetDistance(retailer)
  console.log("retailer :>> ", retailer)

  // Extract nessesary functionality from context
  const { activeRetailer, focusOnLocation } = useContext(RetailersContext)

  // Guard clause for context
  if (activeRetailer === undefined || focusOnLocation === undefined) return null

  // Hold ref to the current card
  const ref = useRef<HTMLButtonElement>(null)

  // If desktop, render regular button instead of motion button
  const isDesktop = useGetViewportWidth()

  const checkExists = (val: any) => val && val !== ""

  // Put card content inside reusable variable
  const content = (
    <>
      <h2 className={style.title}>{retailer.name}</h2>
      <span className={style.address}>
        {retailer.address.street}, {retailer.address.town}{" "}
        {retailer.address.postal_code}
      </span>
      <span className={style.distance}>
        {distance ? `${distance} Miles` : ""}
      </span>
      <div className={style.buttons}>
        <LinkButton
          className={style.button}
          href={`https://maps.google.com/?q=${retailer.name}`}
        >
          <img
            src={directionsIcon}
            className={style.icon}
            alt="Directions icon - sends to google maps"
          />
          Directions
        </LinkButton>
        {checkExists(retailer.address.phone) !== "" && (
          <LinkButton
            external
            className={style.button}
            href={`tel:${retailer.address.phone}`}
          >
            <img
              src={callIcon}
              className={style.icon}
              alt="Call icon - opens call manager with contact number loaded"
            />
            Call
          </LinkButton>
        )}
        {checkExists(retailer.address.website) && false && (
          <LinkButton
            className={style.button}
            href={retailer.address.website}
            external
          >
            <img
              src={webIcon}
              className={style.icon}
              alt="Website icon - opens retailer website"
            />
            Website
          </LinkButton>
        )}
      </div>
    </>
  )

  return (
    <>
      {isDesktop.isDesktop ? (
        <button
          ref={ref}
          className={`${style.card} ${className || ""}`}
          // Apply animations
          {...wrapperAnimations(index)}
          onClick={() =>
            focusOnLocation({
              lat: retailer.address.latitude,
              lng: retailer.address.longitude,
            })
          }
        >
          {content}
        </button>
      ) : (
        <motion.button
          ref={ref}
          className={`${style.card} ${className || ""}`}
          // Apply animations
          {...wrapperAnimations(index)}
          onClick={() =>
            focusOnLocation({
              lat: retailer.address.latitude,
              lng: retailer.address.longitude,
            })
          }
        >
          {content}
        </motion.button>
      )}
    </>
  )
}

export default RetailerCard
