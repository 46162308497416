import React, { useContext } from "react"
import Button from "../../../utilities/buttons/primary-button/primaryButton"
import { RetailersContext } from "../retailer-page-hooks/retailersContext"
import style from "./retailersPostcodeSearch.module.scss"

const RetailersPostcodeSearch = () => {
  // Bring in logic from context
  const { postcode, setPostcode, handleSubmit, setActiveRetailer } = useContext(
    RetailersContext
  )

  // Guard clause for undefined context
  if (
    typeof postcode === "undefined" ||
    !setPostcode ||
    !handleSubmit ||
    !setActiveRetailer
  )
    return null

  // Prevent form from reloading page and run submit funtion
  const handleSubmitInternal = (event: any) => {
    event.preventDefault()
    handleSubmit()
  }
  return (
    <form
      className={style.search}
      onSubmit={handleSubmitInternal}
      onClick={() => setActiveRetailer(null)}
    >
      <h4 className={style.label}>Search by postcode</h4>
      <div className={style.searchWrapper}>
        <label className={style.inputWrapper}>
          <input
            type="text"
            className={style.input}
            value={postcode}
            placeholder="ST1 NKY"
            onChange={e => setPostcode(e.target.value)}
          />
        </label>
        {/* @ts-ignore */}
        <Button onClick={handleSubmitInternal} className={style.submit}>
          SEARCH
        </Button>
      </div>
      <span className={style.subtitle}>
        We don’t store any location data you give to us.
      </span>
    </form>
  )
}

export default RetailersPostcodeSearch
