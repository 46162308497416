import { AnimatePresence, motion } from "framer-motion"
import React, { useContext, useEffect, useRef } from "react"
import { RetailersContext } from "../retailer-page-hooks/retailersContext"
import RetailerCard from "./retailer-card/retailerCard"
import style from "./retailerCards.module.scss"
import exitIcon from "../../../../images/close-rounded-black-18dp.svg"
import Button from "../../../utilities/buttons/primary-button/primaryButton"
import { useGetViewportWidth } from "../../../hooks/viewport"

const elementAnimations = (delay?: boolean) => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    ...(delay && {
      transition: {
        delay: 1,
      },
    }),
  },
})

const RetailerCards = () => {
  // Get nearest retailers from context
  const {
    nearestRetailers,
    activeRetailer,
    showCards,
    setActiveRetailer,
    setShowCards,
  } = useContext(RetailersContext)

  //   Guard clause for no nearest retailers
  if (
    nearestRetailers === undefined ||
    activeRetailer === undefined ||
    setShowCards === undefined ||
    setActiveRetailer === undefined ||
    showCards === undefined
  )
    return null

  // Hold a ref to the wrapper element
  const ref = useRef<HTMLDivElement>(null)

  // Logic for whether to show the content
  const showContent = (nearestRetailers && showCards) || activeRetailer

  useEffect(() => {
    if (!ref?.current) return
    // Scroll card into view when all loaded
    ref.current.scrollTo({ left: 0 })
  }, [ref, nearestRetailers])

  const handleExitClick = () => {
    // @ts-ignore
    setActiveRetailer(null)
    // @ts-ignore
    setShowCards(false)
  }

  // If desktop, render regular divs instead of motion divs
  const { isDesktop } = useGetViewportWidth()

  return (
    <div className={style.container}>
      {showContent && (
        <motion.div
          key="header"
          className={style.header}
          {...elementAnimations()}
        >
          {nearestRetailers && showCards && <h4>Nearest Retailers</h4>}
          <button onClick={handleExitClick} className={style.exitWrapper}>
            <img
              src={exitIcon}
              alt="Exit icon - closes retailer cards section"
            />
          </button>
        </motion.div>
      )}
      {nearestRetailers && !showCards && !activeRetailer && (
        <motion.div
          key="showCardsButton"
          className={style.buttonWrapper}
          {...elementAnimations(!isDesktop)}
        >
          <Button
            className={style.button}
            small
            onClick={() => setShowCards(true)}
          >
            Show my nearest retailers
          </Button>
        </motion.div>
      )}

      <motion.div
        className={`${style.cardsWrapper} ${showContent ? style.open : ""}`}
        ref={ref}
      >
        <div className={style.spacer} />

        {/* Animated presence if not desktop */}
        {isDesktop ? (
          <>
            {activeRetailer ? (
              <RetailerCard
                className={style.activeCard}
                retailer={activeRetailer}
                key={`activeRetailerCard_${activeRetailer._uid}`}
                index={0}
              />
            ) : (
              showCards &&
              nearestRetailers &&
              nearestRetailers.map((retailer, index) => (
                <RetailerCard
                  retailer={retailer}
                  key={`retailerCard_${retailer._uid}_${index}`}
                  index={index}
                />
              ))
            )}
          </>
        ) : (
          <AnimatePresence exitBeforeEnter>
            {activeRetailer ? (
              <RetailerCard
                className={style.activeCard}
                retailer={activeRetailer}
                key={`activeRetailerCard_${activeRetailer._uid}`}
                index={0}
              />
            ) : (
              showCards &&
              nearestRetailers &&
              nearestRetailers.map((retailer, index) => (
                <RetailerCard
                  retailer={retailer}
                  key={`retailerCard_${retailer._uid}_${index}`}
                  index={index}
                />
              ))
            )}
          </AnimatePresence>
        )}
        <div className={style.spacer} />
      </motion.div>
    </div>
  )
}

export default RetailerCards
