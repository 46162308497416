import React from "react"
import RetailersPageContent from "../../components/page-specific/retailers-page-components/retailer-page-content/retailersPageContent"
import { RetailersContextWrapper } from "../../components/page-specific/retailers-page-components/retailer-page-hooks/retailersContext"
import SEO from "../../components/utilities/seo/SEO"
import Layout from "../../layout/layout"
import style from "./retailers.module.scss"


// page schema markup
const schema = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Find a retailer for the OCD range",
  "description": "Find an OCD retailer - Search by postcode and find your closest retailer today.",
  "publisher": {
      "@type": "Organization",
      "name": "OCD"
  }
}

  
const RetailersPage = () => {
  return (
    <Layout
      darkHeader
      title="Find a Retailer"
      noFooter
      className={style.retailersPage}
    >
    <SEO 
      lang="en" 
      title="Retailer search | Find the nearest OCD retailer to you" 
      description="Find an OCD retailer - The OCD range is sold by many retailers in the UK. Find your closest retailer today."  
      image="https://ocdonline.co.uk/images/OCD_All.png"
      keywords="Retailers, find, OCD, map, nearest, OCD shop" 
      schemaMarkup={schema} 
      meta
    />
      {/* Bring in retailers context and logic */}
      <RetailersContextWrapper>
        <RetailersPageContent />
      </RetailersContextWrapper>
    </Layout>
  )
}

export default RetailersPage
