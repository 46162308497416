import React, { useContext } from "react"
import RetailersPostcodeSearch from "../retailers-postcode-search/retailersPostcodeSearch"
import RetailersMap from "../retailer-map/retailerMap"
import style from "./retailersPageContent.module.scss"
import RetailerCards from "../retailer-cards/retailerCards"
import { RetailersContext } from "../retailer-page-hooks/retailersContext"

const RetailersPageContent = () => {

  const {
    showCards,
  } = useContext(RetailersContext)

  return (
    <div className={style.content}>
      <div className={`${style.postcodesCol} ${showCards ? style.open : ""}`}>
        <RetailersPostcodeSearch />
          <div className={`${showCards ? style.shadow : ""}`}></div>
          <RetailerCards />        
      </div>
      <RetailersMap />
    </div>
  )
}

export default RetailersPageContent
